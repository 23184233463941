.Schedule.active .collapsible-header > h6 {
    color: var(--secondary-bg-color);
}

.Schedule .collapsible-header > h6 {
    color: var(--primary-bg-color);
}

.Schedule .collapsible-header{
    margin-bottom: 5px;
    border: none;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
}

.Schedule.active .collapsible-header{
    background-color: var(--primary-bg-color);
    color: var(--secondary-bg-color);
}

.Schedule.active .collapsible-body{
    padding: 0px;
    display: flex !important;
    flex-flow: column;
    align-items: center;
}

.Slots{
    display: block;
    padding: 8px;
}

.chip.TimeSlot{
    background-color: white;
    color: var(--primary-bg-color);
    font-size: 15px;
    cursor: pointer;
    transition: all 0.2s;
    width: 100px;
    box-shadow: -1px 1px 2px;
    margin: 5px;
}

.chip.TimeSlot:hover{
    box-shadow: -2px 2px 4px 0px black;
    transform: translateY(-5px);
}

.chip.TimeSlot.Checked{
    border: 2px solid var(--secondary-font-color);
}