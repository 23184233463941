/* .Staff{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
} */

.Staff.chip{
    background-color: white;
    color: var(--secondary-font-color);
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s;
    box-shadow: -1px 1px 2px #555;
    margin: 5px;
    height: fit-content;
}

.Staff:hover{
    box-shadow: -5px 5px 10px 0px black;
    transform: translateY(-5px);
}

.Staff.Checked{
    border: 2px solid var(--secondary-font-color);
}

@media (min-width: 768px){
    /* .Staff{
        width: 50%;
    } */
}