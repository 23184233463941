.SummaryContainer{
    display: flex;
    justify-content: center;
    padding: 8px;
    border-radius: 8px;
}

.SummaryHeadings, .SummaryContent{
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    padding: 10px;
}

.SummaryHeadings > p, .SummaryContent > p{
    height: 40px;
    font-size: 16px;
    text-align: left;
}

.SummaryHeadings{
    font-weight: bold;
    color: var(--secondary-font-color);
}

@media (min-width: 768px){
    .SummaryContainer{
        width: 75%;
        margin: 30px 12.5%;
    }
}