:root{
  --primary-bg-color:#20252a;
  --secondary-bg-color: #4dc;
  --secondary-font-color:teal;
}

::-webkit-scrollbar-track
{
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #fff;
  border-radius: 10px;
}

::-webkit-scrollbar
{
    height: 10px;
    width: 6px;
    background-color: #fff;
}

::-webkit-scrollbar-thumb
{
    background-color:var(--primary-bg-color);
    border-radius: 10px;
}

h6{
  color: var(--secondary-font-color);
}

.App {
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.AppHeader{
  width: 100%;
  height: 100px;
  background-color: var(--primary-bg-color);
  color: var(--secondary-bg-color);
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0px;
  z-index: 20;
}

.MainContent{
  margin-top: 130px;
  width: 100%;
}

.ConfirmationContainer {
  width: 100%;
  height: 75vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.ActionDiv{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.btn.ActionBtn{
  text-transform: none !important;
  font-weight: bold;
  color: var(--secondary-font-color) !important;
  font-size: 16px;
}

.btn-flat.ActionBtn{
  text-transform: none !important;
  font-weight: bold;
  font-size: 16px;
}

.btn.ActionBtn:hover,.btn.ActionBtn:focus,.btn-flat.ActionBtn:hover,.btn-flat.ActionBtn:focus{
  background-color: transparent;
}

.ProgressBar { 
	height: 25px;
  position: fixed;
  top: 100px;
  left: 0px;
  width: 100%;
	background: #555;
	padding: 5px 0px;
  box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
  z-index: 20;
}

.ProgressBar > span {
  display: block;
  height: 100%;
  background-color: var(--secondary-bg-color);
  box-shadow: 
    inset 0 2px 9px  rgba(255,255,255,0.3),
    inset 0 -2px 6px rgba(0,0,0,0.4);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
  overflow: hidden;
  transition: width 0.5s;
}

.ProgressBar > span:after, .Animated > span > span {
  content: "";
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  background-image: linear-gradient(
    -45deg, 
    rgba(255, 255, 255, .4) 25%, 
    transparent 25%, 
    transparent 50%, 
    rgba(255, 255, 255, .4) 50%, 
    rgba(255, 255, 255, .4) 75%, 
    transparent 75%, 
    transparent
  );
  z-index: 11;
  background-size: 50px 50px;
  overflow: hidden;
  animation: flow 1s linear infinite;
}

.Animated > span:after {
  display: none;
}

.MainContainer{
  width: 100%;
  min-height: 75vh;
  background-color: #efefef !important;
  border-radius: 5px;
  padding: 10px;
  margin: 0px !important;
}

.SectionContainer{
  width: 100%;
  height: 70vh;
  overflow-y: auto;
  padding: 8px;
  margin: 10px 0px;
}

.chip.card{
  background-color: var(--primary-bg-color);
  color: var(--secondary-bg-color);
  font-size: 16px;
  cursor: pointer;
  height: unset;
  transition: all 0.2s;
  -webkit-tap-highlight-color: transparent;
  flex-basis: 20%;
  width: 80%;
  padding-top: 3px;
  padding-bottom: 3px;
}

.chip.card:hover{
  box-shadow: -2px 2px 4px 0px black;
  transform: translateY(-5px);
}

.chip.card.Checked{
  border: 3px solid steelblue;
}

.ChipContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.ServiceContainer{
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 15px;
}

.ScheduleContainer{
  width: 100%;
}

.DateContainer{
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

input#startDate{
  width: fit-content;
  font-weight: bold;
  color: var(--secondary-font-color);
  border-bottom: 2px solid var(--primary-bg-color);
  cursor: pointer;
}

button.btn.SubmitBtn {
  background-color: var(--primary-bg-color);
  color: var(--secondary-bg-color);
  text-transform: capitalize;
}

/* Client Details Form */

.FormContainer{
  position: fixed;
  width: 98%;
  display: flex;
  flex-flow: column;
  align-items: center;
  z-index: 10;
  top: -100vh;
  transition: top 0.5s,box-shadow .25s !important;
  left: 1%;
}

.FormOpen{
  top: 20vh;
}

.FormHeader{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FormAction{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BackDrop{
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5 !important;
}

.AppFooter{
  width: 100%;
  background-color: var(--primary-bg-color);
  color: var(--secondary-bg-color);
  padding: 8px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-around;
}

.AppFooter a:link,.AppFooter a:visited,.AppFooter a:active{
  color: var(--secondary-bg-color);
}

@media (min-width: 768px){
  .ChipContainer{
    flex-flow: row;
  }
  .ScheduleContainer{
    width: 75%;
    margin-left: 12.5%;
  }
  .FormContainer{
    width: 40%;
    left: 30%;
  }
  .AppFooter{
    flex-flow: row;
  }
}

@keyframes flow {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
