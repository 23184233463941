body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Josefin Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ToastSuccess{
  background-color: #4CAF50 !important;
}

.ToastFailure{
  background-color: #F44336 !important;
}