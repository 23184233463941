.PaypalContainer{
    padding: 8px;
    border-radius: 8px;
    width: 100%;
}

.Greeting{
    font-size: 16px;
    margin: 4px;
    background: var(--primary-bg-color);
    color: white;
    border-radius: 8px;
    padding: 5px;
}

.Greeting > span{
    color: var(--secondary-bg-color);
    font-size: 20px;
}

.CheckoutSummary{
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border-radius: 8px;
    margin: 5px;
}

.CheckoutHeading, .CheckoutValue{
    display: flex;
    flex-flow: column;
    padding: 10px;
}

.CheckoutHeading > p, .CheckoutValue > p{
    height: 40px;
    font-size: 16px;
    text-align: left;
}

.CheckoutHeading{
    color: var(--secondary-font-color);
    align-items: flex-start;
}

.CheckoutValue{
    align-items: flex-end;
    min-width: 100px;
}

@media (min-width: 768px){
    .PaypalContainer{
        width: 75%;
        margin: auto;
    }
    .CheckoutSummary{
        justify-content: center;
    }
    .CheckoutHeading > p, .CheckoutValue > p{
        font-size: 18px;
    }
}