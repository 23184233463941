.Service{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ServiceContent{
    width: 100%;
    padding: 8px;
    transition: all 0.2s;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
}

.ServiceContent:hover{
    box-shadow: -5px 5px 10px 0px black;
    transform: translateY(-5px);
}

.ServiceDetail{
    text-align: center;
    margin: -8px;
}

.ServiceContent.Checked{
    border: 2px solid var(--secondary-font-color);
}

@media (min-width: 768px){
    .Service{
        width: 50%;
    }
    .ServiceContent{
        flex-flow: row;
    }
    .ServiceDetail{
        text-align: left;
        margin: 0px 20px;
    }
}